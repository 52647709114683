<template>
  <div>
    <div v-if="fetch_data" class="Static">
      <div v-html="fetch_data.body"></div>
    </div>
    <div v-if="not_found">
      <NotFound/>
    </div>
  </div>
</template>

<script>
import NotFound from '@/components/NotFound.vue'
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {
    NotFound
  },
  data() {
    return {
      fetch_data: null,
      not_found: false,
    }
  },
  watch: {
    '$route'() {
      this.fetch_data = null
      this.not_found = false
      this.fetchStatic()
    }
  },
  created() {
  },
  mounted() {
    this.fetchStatic()
  },
  methods: {
    fetchStatic() {
      // console.log(this.$route.path)
      /*
      const month = this.$route.query.month ? Number(this.$route.query.month) : new Date().getMonth() + 1;
      const uri = this.$route.path !== '/lp' && this.$route.path !== '/lp/' ? this.$route.path :
          (month === 3 || month === 6 || month === 9 || month === 12) ? 'lp_registrable' : 'lp_standby'
      */
      let uri
      if (this.$route.path === '/lp' || this.$route.path === '/lp/') {
        uri = this.isRegistrationEnabled() ? 'lp_registrable' : 'lp_standby'
      } else {
        uri = this.$route.path
      }

      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}contents.json`, {
            params: {
              uri: uri,
              draft: this.$route.query.draft
            },
          })
          .then(response => {
            this.fetch_data = response.data
            this.setMeta({
              title: this.fetch_data.title,
              description: this.fetch_data.description,
            })
            this.setMeta({
              title: this.fetch_data.title,
              description: this.fetch_data.description,
            })
          })
          .catch((response) => {
            this.errorAjax(response)
            // this.not_found = true
          })
          .finally(() => {
            this.finishAjax()
            if (location.hash) {
              setTimeout(() => {
                this.scrollTo(location.hash)
              }, 200)
            }
            // user js への onload処理
            if (typeof _million_onload !== 'undefined') {
              window._million_onload(this.fetch_data.uri);
            }
          })
    }
  }
}
</script>
